import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { UserContext } from 'contexts/user';
import { CartContext } from 'contexts/cart';
import { MobileContext } from 'contexts/mobile';
import darkLogo from 'assets/images/logo-base.png';
import Filter from 'components/residents/Filter';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchLinkedAccounts } from 'services/query';
import { toast } from 'react-toastify';
import { handleApiError } from 'utility';
import { switchLinkedUser } from 'services/mutations';
import { setRefreshToken, setToken } from 'services/auth';

const Header = ({ toggleSideBar }) => {
  const {
    userObject: data,
    isResident,
    isAdmin,
    refreshProvider
  } = useContext(UserContext);
  const { toggleCart, cartItems } = useContext(CartContext);
  const { isTab } = useContext(MobileContext);
  const filterRef = useRef(null);
  const { data: linkedAccounts } = useQuery(
    ['linked_accounts'],
    fetchLinkedAccounts,
    {
      onError: (error) => {
        toast.error(handleApiError(error));
      }
    }
  );

  const queryClient = useQueryClient();

  const { mutate } = useMutation(switchLinkedUser, {
    onError: (error) => {
      return toast.error(handleApiError(error));
    },
    onSuccess: (resData) => {
      setToken(resData.access);
      setRefreshToken(resData.refresh);
      queryClient.invalidateQueries();
      refreshProvider(true);
    }
  });

  const handleSwitchAccount = (linkedAccount) => {
    mutate({
      linked_account: linkedAccount
    });
    filterRef.current.closeFilter();
  };

  const path = isResident
    ? '/user-profile'
    : isAdmin
    ? '/admin/user-profile'
    : '/super-admin/profile';

  return (
    <header className="header">
      <div className="header-container">
        <Navbar bg="light" expand="false">
          <button className="navbar-toggler" onClick={toggleSideBar}>
            <span className="icon-menu" />
          </button>
          <div className="d-flex flex-fill flex-grow-1 justify-content-center d-md-none">
            <NavLink to="/">
              <img src={darkLogo} height="40px" />
            </NavLink>
          </div>

          <Navbar.Collapse
            id="navbar"
            className="justify-content-end flex-grow-0"
          >
            <Nav>
              {isResident && linkedAccounts && linkedAccounts.length > 0 && (
                <div className={classnames('nav-link nav-link--icon')}>
                  <Filter
                    label="Switch Account"
                    ref={filterRef}
                    onCancel={() =>
                      filterRef.current && filterRef.current.closeFilter()
                    }
                    showDefaultAction={false}
                  >
                    {linkedAccounts.map((account) => {
                      return (
                        <p
                          className="filter-menu__item__listitem"
                          key={account.id}
                          onClick={() => handleSwitchAccount(account.id)}
                        >
                          <b>
                            {account.firstname} {account.lastname}{' '}
                          </b>
                          <br />
                          <span className="text-lowercase">
                            {account.email}
                          </span>{' '}
                          <br />
                          {account.information.meter_no} <br />
                        </p>
                      );
                    })}
                  </Filter>
                </div>
              )}
              {isResident && (
                <span
                  role="link"
                  className={classnames('nav-link nav-link--icon', {
                    'nav-link--icon-active': cartItems?.data.length > 0
                  })}
                  onClick={toggleCart}
                >
                  <span className="icon-cart" />
                </span>
              )}

              <NavLink to={path} className="nav-link nav-link--profile">
                {!data?.image ? (
                  <span className="profile__avatar"></span>
                ) : (
                  <img
                    className="profile__avatar"
                    src={data?.image}
                    alt="profile-image"
                  />
                )}
                {!isTab && (
                  <span>
                    <span className="profile__text">{`${data?.firstname} ${data?.lastname}`}</span>
                    <span className="profile__text profile__text--light">
                      {data?.email}
                    </span>
                  </span>
                )}
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

Header.propTypes = {
  toggleSideBar: PropTypes.func
};
export default Header;
