import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
// import { toast } from 'react-toastify';
// import { handleApiError } from 'utility';

import { verifyDomain } from './mutations';

const DOMAIN = process.env.REACT_APP_DOMAIN || 'localhost:3000';

export const getSubdomain = () => {
  const hostname = window.location.hostname.toLowerCase();

  const hasDomain = hostname.includes(DOMAIN) || hostname.includes('localhost');

  if (hasDomain) {
    return hostname.split(DOMAIN)[0].split('.')[0];
  }
  return hostname;
};

const useSubdomain = () => {
  const domainObject = localStorage.getItem('domainObject');
  const [isDomainInvalid, setIsDomainInvalid] = useState(false);

  const domain = getSubdomain();

  const { mutate, isLoading } = useMutation(verifyDomain, {
    onSuccess: (data) => {
      localStorage.setItem('domainObject', JSON.stringify(data.data));
    },
    onError: () => {
      setIsDomainInvalid(true);
    }
  });

  useEffect(() => {
    if (domain === 'console') {
      setIsDomainInvalid(false);
      return;
    }
    mutate({ domain });
    // eslint-disable-next-line
  }, [domain]);

  return { isLoading, domainObject: JSON.parse(domainObject), isDomainInvalid };
};

export default useSubdomain;
