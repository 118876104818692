import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo-white.png';
import SmallLogo from 'assets/images/logo-white-small.png';
import { AccessControl, UserPermission } from 'VerifiedApp';
import { NavDropdown } from 'components/ui';
import { logOut } from 'services/auth';
import { closeRefDropdown } from 'utility';

const ResidentSidebar = ({ smallSidebar, onNavigate }) => {
  const navigate = useNavigate();
  const hasPermission = UserPermission();
  const location = useLocation();

  const paymentHistoryRef = useRef(null);

  const closeAllDropdowns = useCallback(() => {
    closeRefDropdown(paymentHistoryRef);
  }, []);

  useEffect(() => {
    if (smallSidebar) {
      closeAllDropdowns();
    }
  }, [closeAllDropdowns, smallSidebar]);

  return (
    <div className="sidebar-inner">
      <NavLink
        to="/"
        onClick={onNavigate}
        className={classnames('sidebar__logo', {
          'sidebar__logo--small': smallSidebar
        })}
      >
        <img
          src={Logo}
          className="img-fluid desktop-logo"
          alt="facility bills"
        />

        <img
          src={SmallLogo}
          className="img-fluid small-logo"
          alt="facility bills"
        />
      </NavLink>

      <nav>
        <NavLink
          to="/dashboard"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive,
              noPermission: !hasPermission()
            })
          }
        >
          <span className="icon-dashboard sidebar__icon" />
          <span className="sidebar__text">Dashboard</span>
        </NavLink>

        <AccessControl permission={''}>
          <NavDropdown
            ref={paymentHistoryRef}
            title="My Transactions"
            icon="icon-message-text"
            className={classnames({
              activeClass: location.pathname.includes('/transactions')
            })}
          >
            <li>
              <NavLink
                to="/transactions?bill_type=POWER"
                onClick={onNavigate}
                className={classnames('sidebar__link', {
                  activeClass: location.search.includes('bill_type=POWER'),
                  noPermission: !hasPermission('')
                })}
              >
                Power
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/transactions?bill_type=service"
                onClick={onNavigate}
                className={classnames('sidebar__link', {
                  activeClass: location.search.includes('bill_type=service'),
                  noPermission: !hasPermission('')
                })}
              >
                Service
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/transactions?bill_type=Top_Up"
                onClick={onNavigate}
                className={classnames('sidebar__link', {
                  activeClass: location.search.includes('bill_type=Top_Up'),
                  noPermission: !hasPermission('')
                })}
              >
                Top Up
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/transactions?bill_type=WATER"
                onClick={onNavigate}
                className={classnames('sidebar__link', {
                  activeClass: location.search.includes('bill_type=WATER'),
                  noPermission: !hasPermission('')
                })}
              >
                Water
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/transactions?bill_type=Other"
                onClick={onNavigate}
                className={classnames('sidebar__link', {
                  activeClass: location.search.includes('bill_type=Other'),
                  noPermission: !hasPermission('')
                })}
              >
                Others
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/transactions?type=Outstanding"
                onClick={onNavigate}
                className={classnames('sidebar__link', {
                  activeClass: location.search.includes(
                    'bill_type=Outstanding'
                  ),
                  noPermission: !hasPermission('')
                })}
              >
                Outstanding
              </NavLink>
            </li>
          </NavDropdown>
        </AccessControl>

        <NavLink
          to="/bills"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive,
              noPermission: !hasPermission('')
            })
          }
        >
          <span className="icon-empty-wallet sidebar__icon" />
          <span className="sidebar__text">Pay Bills</span>
        </NavLink>

        <NavLink
          to="/bookings"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive,
              noPermission: !hasPermission('')
            })
          }
        >
          <span className="icon-activity sidebar__icon" />
          <span className="sidebar__text">My Visitors</span>
        </NavLink>

        <NavLink
          to="/wallet"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive,
              noPermission: !hasPermission('')
            })
          }
        >
          <span className="icon-card-receive sidebar__icon" />
          <span className="sidebar__text">Wallet</span>
        </NavLink>

        <NavLink
          to="/notification"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive,
              noPermission: !hasPermission('')
            })
          }
        >
          <span className="icon-estate-info sidebar__icon" />
          <span className="sidebar__text">Notifications</span>
        </NavLink>

        <NavLink
          to="/complaints"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive,
              noPermission: !hasPermission('')
            })
          }
        >
          <span className="icon-card-receive sidebar__icon" />
          <span className="sidebar__text">Complaints</span>
        </NavLink>

        <NavLink
          to="/votes"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive,
              noPermission: !hasPermission('')
            })
          }
        >
          <span className="icon-chart sidebar__icon" />
          <span className="sidebar__text">Vote</span>
        </NavLink>

        <NavLink
          to="/faqs"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive,
              noPermission: !hasPermission('')
            })
          }
        >
          <span className="icon-document-text sidebar__icon" />
          <span className="sidebar__text">FAQ</span>
        </NavLink>

        <NavLink
          to="/emergency"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive,
              noPermission: !hasPermission('')
            })
          }
        >
          <span className="icon-receipt-edit sidebar__icon" />
          <span className="sidebar__text">Emergency</span>
        </NavLink>

        <div
          role="link"
          className="sidebar__link logout_link"
          onClick={() =>
            logOut(() => {
              onNavigate();
              setTimeout(() => navigate('/login'), 300);
            })
          }
        >
          <span className="icon-logout sidebar__icon" />
          <span className="sidebar__text">Log Out</span>
        </div>
      </nav>
    </div>
  );
};

ResidentSidebar.propTypes = {
  smallSidebar: PropTypes.bool,
  onNavigate: PropTypes.func
};

export default ResidentSidebar;
