import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ReactModal } from 'react-bootstrap';
import classnames from 'classnames';

export const Modal = ({
  centered,
  children,
  className,
  handleClose,
  show,
  size = 'lg',
  title
}) => {
  return (
    <ReactModal
      show={show}
      onHide={handleClose}
      centered={centered}
      size={size}
      className={classnames(className)}
    >
      <ReactModal.Header closeButton>
        {title && <ReactModal.Title>{title}</ReactModal.Title>}
      </ReactModal.Header>

      <ReactModal.Body>{children}</ReactModal.Body>
    </ReactModal>
  );
};

Modal.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string
};
