import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const Select = (props) => {
  const { value, error, name, label } = props;
  const valueError =
    typeof value === 'string'
      ? !value?.length
      : !Object?.values(value || {})?.length;
  const hasError = valueError && error;

  return (
    <div className={classnames('form__group', props.className)}>
      {props.label && <span className="form__label">{props.label}</span>}

      <ReactSelect
        id={props.id}
        inputProps={{
          autoComplete: 'off',
          autoCorrect: 'off',
          spellCheck: 'off'
        }}
        onChange={props.onChange}
        value={props.value}
        required={props.required}
        options={props.options}
        isMulti={props.isMulti}
        className="form__select"
        classNamePrefix="select"
        placeholder={props.placeholder}
        isSearchable={props.isSearchable}
        isClearable={props.isClearable}
        isDisabled={props.isDisabled}
        // menuIsOpen={true}
      />

      <small
        style={{
          color: '#F48989',
          fontSize: 12,
          display: hasError ? 'block' : 'none'
        }}
      >
        {label || name} is required
      </small>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  error: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool
};
