import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getDecodedJwt, isAuthenticated } from 'services/auth';
import { fetchUserById } from 'services/query';
import { handleApiError } from 'utility';

export const UserContext = createContext({
  isAdmin: false,
  isLoading: false,
  isResident: false,
  roles: [],
  userId: '',
  refreshProvider: () => {},
  userObject: null
});

const UserContextProvider = ({ children }) => {
  const [, refreshProvider] = useState();
  const decodedUser = getDecodedJwt();
  const userId = decodedUser?.user_id;
  const roles = decodedUser?.roles;
  const isAdmin = decodedUser.roles?.includes('ESTATEADMIN');
  const isResident = decodedUser.roles?.includes('RESIDENT');
  const isSuperAdmin = decodedUser.roles?.includes('SUPERADMIN');

  const {
    data: userObject,
    isLoading,
    isRefetching
  } = useQuery(['user-by', { id: userId }], fetchUserById, {
    cacheTime: 0,
    enabled: isAuthenticated() && !!userId,
    onError: (error) => {
      toast.error(handleApiError(error));
    },
    onSuccess: () => {}
  });

  if (isResident) {
    if (document.body.classList.contains('theme--default')) {
      document.body.classList.remove('theme--default');
    }

    document.body.classList.add('theme--resident');
  } else if (isAdmin) {
    if (document.body.classList.contains('theme--resident')) {
      document.body.classList.remove('theme--resident');
    }

    document.body.classList.add('theme--default');
  } else {
    document.body.classList.add('theme--default');
  }

  return (
    <UserContext.Provider
      value={{
        isAdmin,
        isLoading: isLoading || isRefetching,
        isResident,
        roles,
        refreshProvider,
        userId,
        userObject,
        isSuperAdmin
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node
};

export default UserContextProvider;
