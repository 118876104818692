import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePickerComponent from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';

export const DatePicker = forwardRef((props, ref) => {
  const dateProps = { ...props };
  delete dateProps.label;

  return (
    <Form.Group
      className={classnames('form__group', props.className)}
      controlId={props.name}
    >
      {props.label && (
        <Form.Label className="form__label">{props.label}</Form.Label>
      )}

      <DatePickerComponent
        {...dateProps}
        name={props.name}
        ref={ref}
        customInput={<Form.Control className="form__control" />}
        selected={props.selected}
        onChange={props.onChange}
        startDate={props.startDate}
        endDate={props.endDate}
        placeholderText={props.placeholder}
      />
    </Form.Group>
  );
});

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  className: PropTypes.string,
  endDate: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  selected: PropTypes.object,
  startDate: PropTypes.object
};
