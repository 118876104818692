export const allowedRoutes = [
  // resident routes
  '/bills',
  '/bookings',
  '/bookings/create',
  '/bookings/update',
  '/votes',
  '/votes/poll',
  '/complaints',
  'complaints/create',

  // admin routes
  '/admin/visitor',
  '/admin/booking-managemnent',
  '/admin/bills',
  '/admin/bills/new',
  '/admin/polls',
  '/admin/complaints',
  '/admin/complaints/view/',
  'complaints-category'
];
