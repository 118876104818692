import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from './Button';

export const Card = ({
  cardHeading,
  detailArray,
  headingPostion,
  row = false,
  children,
  flex = false
}) => {
  const getSubHeadingLayout = (subHeading) => {
    switch (subHeading) {
      case 'Rejected':
        return <Button variant="outline-danger p-2">{subHeading}</Button>;
      case 'Paid':
        return <span style={{ color: 'green' }}>{subHeading}</span>;
      case 'Outstanding':
        return <span style={{ color: 'red' }}>{subHeading}</span>;
      case 'Signed In':
        return <Button variant="outline-success p-2">{subHeading}</Button>;
      default:
        return <span>{subHeading}</span>;
    }
  };

  const renderDetail = (heading, subHeading) => {
    return (
      <div
        className={`visitor mb-4 ${
          flex ? 'd-flex' : 'd-block'
        } align-content-center justify-content-between`}
      >
        <h6 className="visitor_heading mb-2">{heading}</h6>
        <p>{getSubHeadingLayout(subHeading)}</p>
      </div>
    );
  };

  return (
    <section className="card-container rounded w-100 h-100">
      <h5 className={classnames('card_heading mb-5', headingPostion)}>
        {cardHeading}
      </h5>

      <div className="row">
        {detailArray?.map(({ heading, subHeading }) => {
          return row ? (
            <div className="col-xs-12 col-md-4" key={heading}>
              {renderDetail(heading, subHeading, flex)}
            </div>
          ) : (
            <div key={heading}>{renderDetail(heading, subHeading, flex)}</div>
          );
        })}
      </div>
      {!detailArray && children && <div>{children}</div>}
    </section>
  );
};

Card.propTypes = {
  cardHeading: PropTypes.string,
  detailArray: PropTypes.array,
  headingPostion: PropTypes.string,
  row: PropTypes.bool,
  children: PropTypes.node,
  flex: PropTypes.bool
};
