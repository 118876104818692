import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'contexts/user';
import { MobileContext } from 'contexts/mobile';
import AdminSidebar from './AdminSidebar';
import ResidentSidebar from './ResidentSidebar';
import SuperAdminSidebar from './SuperAdminSidebar';

const Sidebar = ({ hoveSideBar, smallSidebar, closeSideBar }) => {
  const { isResident, isSuperAdmin } = useContext(UserContext);
  const { isTab } = useContext(MobileContext);

  const renderSidebar = () => {
    if (isResident) {
      return (
        <ResidentSidebar
          smallSidebar={smallSidebar}
          onNavigate={closeSideBar}
        />
      );
    }

    if (isSuperAdmin) {
      return (
        <SuperAdminSidebar
          smallSidebar={smallSidebar}
          onNavigate={closeSideBar}
        />
      );
    }

    return (
      <AdminSidebar smallSidebar={smallSidebar} onNavigate={closeSideBar} />
    );
  };

  if (isTab) {
    <aside className="sidebar scrollbar">{renderSidebar()}</aside>;
  }

  return (
    <aside
      className="sidebar scrollbar"
      onMouseEnter={hoveSideBar}
      onMouseLeave={hoveSideBar}
    >
      {renderSidebar()}
    </aside>
  );
};

Sidebar.propTypes = {
  closeSideBar: PropTypes.func,
  hoveSideBar: PropTypes.func,
  smallSidebar: PropTypes.bool
};

export default Sidebar;
