import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { fetchCurrentAds } from 'services/query';
import { toast } from 'react-toastify';
import { handleApiError } from 'utility';
import { UserContext } from './user';

export const AdvertContext = createContext({
  advertItems: null,
  enabled: false,
  isLoading: false,
  refetch: () => null,
  setEnabled: () => null,
  showAdvert: false,
  toggleAdvert: () => null,
  setShowAdvert: () => null
});

const AdvertContextProvider = ({ children }) => {
  const [showAdvert, setShowAdvert] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const { isAdmin, isResident } = useContext(UserContext);

  const {
    isLoading,
    error,
    data: advertItems,
    isError,
    refetch
  } = useQuery('currentAdverts', fetchCurrentAds, {
    enabled
  });

  const toggleAdvert = () => setShowAdvert((prevState) => !prevState);

  if (isError) {
    toast.error(handleApiError(error));
  }

  return (
    <AdvertContext.Provider
      value={{
        advertItems,
        enabled,
        isLoading,
        setEnabled,
        setShowAdvert,
        showAdvert: (isAdmin || isResident) && showAdvert,
        toggleAdvert,
        refetch
      }}
    >
      {children}
    </AdvertContext.Provider>
  );
};

AdvertContextProvider.propTypes = {
  children: PropTypes.node
};

export default AdvertContextProvider;
