import React, {
  Children,
  forwardRef,
  useImperativeHandle,
  useContext,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'components/ui';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { MobileContext } from 'contexts/mobile';

const Filter = forwardRef(
  (
    {
      children,
      className,
      onCancel,
      onSubmit,
      label = 'Filter By',
      showDefaultAction = true
    },
    passRef
  ) => {
    const [showFilter, setShowFilter] = useState(false);

    const { isMobile } = useContext(MobileContext);

    const ref = useRef(null);
    const arrayChildren = Children.toArray(children);

    const toggleFilter = () => {
      setShowFilter((prevState) => !prevState);
    };

    const closeFilter = () => {
      setShowFilter(false);
    };

    useImperativeHandle(passRef, () => {
      return {
        closeFilter
      };
    });

    return (
      <div className="filter">
        <Popover
          containerClassName={classnames('context', className)}
          isOpen={showFilter}
          positions={['bottom', 'left']}
          reposition={false}
          ref={ref}
          onClickOutside={() => setShowFilter(false)}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
            >
              <div className="filter-menu">
                {Children.map(arrayChildren, (child, index) => {
                  return (
                    <div className="filter-menu__item" key={index}>
                      {child}
                    </div>
                  );
                })}

                {showDefaultAction && (
                  <div className="filter-menu__item filter-menu__buttons">
                    <Button variant="primary" value="submit" onClick={onSubmit}>
                      Submit
                    </Button>

                    <Button
                      className="btn--outline ms-4"
                      value="submit"
                      onClick={onCancel}
                    >
                      Reset
                    </Button>
                  </div>
                )}
              </div>
            </ArrowContainer>
          )}
        >
          <div
            role="button"
            className={classnames('filter__link', className)}
            onClick={toggleFilter}
          >
            {!isMobile && label}
            <span className="icon-funnel ms-4"></span>
          </div>
        </Popover>
      </div>
    );
  }
);

Filter.displayName = 'Filter';

Filter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  label: PropTypes.string,
  showDefaultAction: PropTypes.bool
};

export default Filter;
