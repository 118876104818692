import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MobileContext } from 'contexts/mobile';
import Cart from 'components/residents/Cart';
import { UserContext } from 'contexts/user';
import { AdvertContext } from 'contexts/adverts';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './sidebar';
import Advert from './Advert';

const DefaultLayout = ({ className }) => {
  const [smallSidebar, setSmallSidebar] = useState(false);
  const [hoverSidebar, setHoverSidebar] = useState(false);

  const { isTab } = useContext(MobileContext);
  const { isResident } = useContext(UserContext);
  const { showAdvert } = useContext(AdvertContext);

  useEffect(() => {
    if (isTab) {
      setSmallSidebar(true);
    }
  }, [isTab]);

  const toggleSideBar = () => {
    setSmallSidebar((prevState) => !prevState);
  };

  const toggleSidebarHover = () => {
    if (smallSidebar) {
      setHoverSidebar((prevState) => !prevState);
    }
  };

  const closeSideBar = () => {
    if (isTab) {
      setSmallSidebar(true);
    }
  };

  return (
    <main
      className={classnames('page page--admin', className, {
        'menu-collasped-active': smallSidebar,
        'sidemenu-hover-deactive': !isTab && !hoverSidebar,
        'sidemenu-hover-active': !isTab && hoverSidebar
      })}
    >
      <Sidebar
        smallSidebar={smallSidebar}
        hoveSideBar={toggleSidebarHover}
        closeSideBar={closeSideBar}
      />

      <div className="page-body">
        <Header toggleSideBar={toggleSideBar} />

        <div className="page-inner scrollbar">
          <Suspense fallback={'Loading...'}>
            <Outlet />
          </Suspense>

          {showAdvert && <Advert />}
        </div>

        {isResident && <Cart />}

        <Footer />
      </div>
    </main>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showAdvert: PropTypes.bool
};

export default DefaultLayout;
