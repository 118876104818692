import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Loader = ({ isLoading, children, fullscreen, fluid }) => {
  return (
    <div
      className={classnames('loader', {
        'loader--fullscreen': fullscreen,
        'loader--fluid': fluid
      })}
    >
      {isLoading ? (
        <>
          <div className="overlay" />
          <div className="spin"></div>
        </>
      ) : (
        <div className="child-container">{children}</div>
      )}
    </div>
  );
};

Loader.propTypes = {
  children: PropTypes.node,
  fluid: PropTypes.bool,
  fullscreen: PropTypes.bool,
  isLoading: PropTypes.bool,
  size: PropTypes.number
};

export default React.memo(Loader);
