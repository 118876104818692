import React, { useContext, useState } from 'react';
import { CartContext } from 'contexts/cart';
import { Modal } from 'react-bootstrap';
import { Button } from 'components/ui';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { checkoutCartItems } from 'services/mutations';
import { getCartData, handleApiError, useAmount } from 'utility';
import { UserContext } from 'contexts/user';
import Loader from 'components/Loader';
import CartCard from './cart-card';

const Cart = () => {
  const queryClient = useQueryClient();
  const { cartItems, showCart, toggleCart, isLoading, refetch } =
    useContext(CartContext);

  const { isResident } = useContext(UserContext);
  const { convertToAmount } = useAmount();
  const navigate = useNavigate();
  const [fetched, setFetched] = useState(false);

  if (isResident && !fetched) {
    refetch();
    setFetched(true);
  }

  const { mutate: onCheckOut, isLoading: isCheckingOut } = useMutation(
    checkoutCartItems,
    {
      onError: (error) => {
        return toast.error(handleApiError(error));
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries('cart');
        queryClient.invalidateQueries('user-by');
        refetch();
        toggleCart();
        navigate(`/bills/checkout/${data.reference}/${data.id}`);
      }
    }
  );

  return (
    <Modal show={showCart} onHide={toggleCart} backdrop={true} className="cart">
      <div className="cart__inner">
        <div className="cart-top">
          <div className="row align-items-center">
            <div className="col-4">
              <div className="cart__close" onClick={toggleCart}>
                <span className="icon-angle-right"></span>
              </div>
            </div>

            <div className="col-4">
              <h5 className="cart__title">YOUR CART</h5>
            </div>

            <div className="filler"></div>
          </div>
        </div>

        {isLoading && (
          <div>
            <Loader />
          </div>
        )}

        {cartItems && cartItems?.data.length === 0 && (
          <div className="d-flex flex-column justify-content-center flex-grow-1 align-items-center">
            <span className="no-data__icon icon-cart"></span>
            <p>Cart is empty!</p>
          </div>
        )}

        {!isLoading && (
          <div className="cart-body">
            <div className="cart__list">
              {cartItems &&
                cartItems?.data.map((item, i) => {
                  return <CartCard bill={getCartData(item)} key={i} />;
                })}
            </div>
          </div>
        )}

        {cartItems && cartItems?.data.length > 0 && (
          <div className="cart-footer">
            <div className="cart__subtotal">
              <span>Subtotal</span>

              <div className="cart__price">
                {convertToAmount(cartItems?.sub_total, '₦')}
              </div>
            </div>

            <Button
              onClick={onCheckOut}
              className="w-100 btn--fw mt-4"
              loading={isCheckingOut}
            >
              Proceed To Checkout
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Cart;
