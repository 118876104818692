import React, {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const NavDropdown = forwardRef(
  ({ children, className, icon, title }, passRef) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const ref = useRef(null);

    const toggleDropdown = () => {
      setShowDropdown((prevState) => !prevState);
    };

    const closeDropdown = () => {
      setShowDropdown(false);
    };

    useImperativeHandle(passRef, () => {
      return {
        closeDropdown
      };
    });

    return (
      <span className="treeview">
        <span
          className={classnames('sidebar__link', className)}
          onClick={toggleDropdown}
        >
          <span className={classnames('sidebar__icon', icon)} />
          <span className="sidebar__text ">{title}</span>
          {!showDropdown && (
            <span
              className="icon-arrow-down me-4 arrow"
              title="icon-arrow-down"
            />
          )}
        </span>

        <ul
          className={classnames('treeview-menu', {
            active: showDropdown,
            inactive: !showDropdown
          })}
          ref={ref}
        >
          {children}
        </ul>
      </span>
    );
  }
);

NavDropdown.displayName = 'NavDropdown';

NavDropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hideDropdown: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string
};
