import React from 'react';
import MobileContextProvider from 'contexts/mobile';
import UserContextProvider from 'contexts/user';
import CartContextProvider from 'contexts/cart';
import AdvertContextProvider from 'contexts/adverts';
import Router from './Router';

function App() {
  return (
    <MobileContextProvider>
      <UserContextProvider>
        <CartContextProvider>
          <AdvertContextProvider>
            <Router />
          </AdvertContextProvider>
        </CartContextProvider>
      </UserContextProvider>
    </MobileContextProvider>
  );
}

export default App;
