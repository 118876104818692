import classnames from 'classnames';
import PropTypes from 'prop-types';
import { AdvertContext } from 'contexts/adverts';
import React, { useContext } from 'react';
import { Carousel } from 'react-bootstrap';

const Advert = ({ className }) => {
  const { advertItems } = useContext(AdvertContext);

  return (
    <section className={classnames('section section-advert', className)}>
      <div className="container">
        <Carousel fade controls={false}>
          {advertItems.map((item, index) => (
            <Carousel.Item key={index}>
              <a href={item.redirect_link} className="advert__link">
                <img className="img-fluid" src={item.image_link} />
              </a>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

Advert.propTypes = {
  className: PropTypes.string
};

export default Advert;
