import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form } from 'react-bootstrap';

export const InputField = forwardRef((props, ref) => {
  const inputProps = { ...props };
  delete inputProps.icon;
  delete inputProps.label;
  delete inputProps.inputClass;

  const { label } = props;
  const { value, error, name } = inputProps;
  const hasError = !value?.length && error;

  return (
    <Form.Group
      className={classnames('form__group', props.className)}
      controlId={props.name}
    >
      {props.label && (
        <Form.Label className="form__label">{props.label}</Form.Label>
      )}

      <div
        className={classnames({
          'form__control--icon-block': props.icon
        })}
      >
        <Form.Control
          {...inputProps}
          ref={ref}
          className={classnames('form__control', props.inputClass, {
            'form__control--icon': props.icon
          })}
        />

        {props.icon && (
          <span className={classnames('form__icon', props.icon)} />
        )}
      </div>
      <small
        style={{
          color: '#F48989',
          fontSize: 12,
          display: hasError ? 'block' : 'none'
        }}
      >
        {label || name} is required
      </small>
    </Form.Group>
  );
});

InputField.displayName = 'InputField';

InputField.propTypes = {
  className: PropTypes.string,
  hasValidate: PropTypes.bool,
  icon: PropTypes.string,
  inputClass: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string
};
