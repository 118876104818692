import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const Checkbox = forwardRef((props, ref) => {
  return (
    <div className={classnames('form__checkbox-group', props.className)}>
      <label className="form__checkbox">
        {props.label && <span className="form__label">{props.label}</span>}

        <input
          type="checkbox"
          ref={ref}
          checked={props.checked}
          readOnly
          {...props}
        />
        <span
          className={classnames('checkmark', {
            'checkmark--radio': props.type === 'radio'
          })}
        ></span>
      </label>
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func
};

Checkbox.defaultProps = {
  type: 'checkbox'
};
