import React from 'react';
import NotFoundImage from 'assets/images/not-found.png';

const DomainNotValid = () => {
  return (
    <section id="no_access" className="no-access">
      <div className="no-access__inner">
        <img src={NotFoundImage} alt="Not found" />

        <h6 className="text-muted text-center">
          We can’t find the page that you’re looking for
        </h6>
      </div>
    </section>
  );
};

export default DomainNotValid;
