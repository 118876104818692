import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export const NoData = ({
  className,
  description,
  icon = 'icon-data',
  image
}) => {
  return (
    <div className="no-data">
      <div className={classnames('no-data__inner', className)}>
        {!image && <span className={classnames('no-data__icon', icon)} />}

        {image && (
          <img
            alt="empty"
            width={100}
            height={100}
            src={image}
            className={classnames('no-data__image', icon)}
          />
        )}

        <p>{description || 'No data was found!'}</p>
      </div>
    </div>
  );
};

NoData.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.string
};
