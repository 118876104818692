import React, { Suspense, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BackgroundIcons from 'assets/images/svg/background-icons.svg';
import Square from 'assets/images/svg/square.svg';
import { Outlet, useNavigate } from 'react-router-dom';
import { isAuthenticated } from 'services/auth';
import { UserContext } from 'contexts/user';

const AuthLayout = ({ className }) => {
  const navigate = useNavigate();
  const { isResident, isAdmin, isSuperAdmin, isLoading } =
    useContext(UserContext);

  useEffect(() => {
    if (isAuthenticated()) {
      if (!isLoading) {
        if (isResident) {
          return navigate('/dashboard');
        }

        if (isAdmin) {
          return navigate('/admin/user-profile');
        }
        if (isSuperAdmin) {
          return navigate('/super-admin/dashboard');
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, isLoading, isResident]);

  return (
    <div className="page page--auth">
      <main className={classnames('auth-grid', className)}>
        <section className="auth-col auth-col--left">
          <h3>Bill Payment</h3>
          <p>
            We at facility bills help estate managers relate, lodge complaints
            and pay bills.
          </p>

          <img
            src={BackgroundIcons}
            alt="background icons"
            className="auth-icons auth-icons--1"
          />

          <img
            src={Square}
            alt="background icons"
            className="auth-icons auth-icons--2"
          />
        </section>

        <section className="auth-col auth-col--right scrollbar">
          <div className="auth__form">
            <Suspense fallback={'Loading...'}>
              <Outlet />
            </Suspense>
          </div>

          <img
            src={BackgroundIcons}
            alt="background icons"
            className="auth-icons auth-icons--1"
          />

          <img
            src={Square}
            alt="background icons"
            className="auth-icons auth-icons--2"
          />
        </section>
      </main>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default AuthLayout;
