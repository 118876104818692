import React, { useContext } from 'react';
import useSubdomain from 'services/useSubdomain';
import 'react-toastify/dist/ReactToastify.css';
import DomainNotValid from 'pages/DomianNotValid';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import { UserContext } from 'contexts/user';

const VerifiedApp = ({ children }) => {
  const { isLoading, isDomainInvalid } = useSubdomain();
  const { isLoading: initializingPermission } = useContext(UserContext);

  if (isLoading || initializingPermission) {
    return (
      <Loader isLoading={isLoading || initializingPermission} fullscreen />
    );
  }

  if (isDomainInvalid) {
    return <DomainNotValid />;
  }
  return children;
};

export default VerifiedApp;

VerifiedApp.propTypes = {
  children: PropTypes.node
};

export const UserPermission = () => {
  const { userObject } = useContext(UserContext);

  const userPermissions = userObject?.permissions;

  function hasPermission(routePermission) {
    if (userPermissions) {
      if (
        !routePermission ||
        routePermission === '' ||
        routePermission === undefined
      ) {
        return true;
      }
      return userPermissions?.includes(routePermission);
    }
    return true;
  }

  return hasPermission;
};

export const AccessControl = ({ permission, children }) => {
  const hasPermission = UserPermission();
  let isArrayPermission = false;

  if (Array.isArray(permission)) {
    isArrayPermission = permission?.some((per) => {
      return hasPermission(per);
    });
  }

  if (isArrayPermission) return children;

  if (!hasPermission(permission)) {
    return;
  }

  return children;
};
