import { lazy } from 'react';

const publicRoutes = [
  {
    path: 'register',
    element: lazy(() => import('pages/residents/auth')),
    useAuth: false,
    layout: null
  }
];

const privateRoutes = [
  {
    path: 'dashboard',
    element: lazy(() => import('pages/residents/Dashboard')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'transactions',
    element: lazy(() => import('pages/residents/Transactions')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'bills',
    element: lazy(() => import('pages/residents/Bills')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'wallet',
    element: lazy(() => import('pages/residents/wallet')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'transactions/invoice',
    element: lazy(() => import('pages/residents/Invoice')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'payment/confirmation/:ref',
    element: lazy(() => import('pages/residents/wallet/WalletConfirmation')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'bookings',
    element: lazy(() => import('pages/residents/booking/index')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'bookings/create',
    element: lazy(() => import('pages/residents/booking/UpserBooking')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'bookings/update/:id',
    element: lazy(() => import('pages/residents/booking/UpserBooking')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'faqs',
    element: lazy(() => import('pages/residents/Faqs')),
    useAuth: true,
    permission: ''
  },
  // {
  //   path: 'notifications',
  //   element: lazy(() => import('pages/residents/Notification')),
  //   useAuth: true,
  //   permission: '',
  //
  // },
  {
    path: 'votes',
    element: lazy(() => import('pages/residents/vote')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'votes/poll/:id',
    element: lazy(() => import('pages/residents/vote/Poll')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'emergency',
    element: lazy(() => import('pages/residents/emergency')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'emergency/contacts',
    element: lazy(() => import('pages/residents/emergency/Emergency')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'user-profile',
    element: lazy(() => import('pages/residents/Profile')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'complaints',
    element: lazy(() => import('pages/residents/complaint')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'complaints/create',
    element: lazy(() => import('pages/residents/complaint/CreateComplaint')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'complaints/:id',
    element: lazy(() => import('pages/residents/complaint/Complaint')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'bills/checkout/:reference/:id',
    element: lazy(() => import('pages/residents/Checkout')),
    useAuth: true,
    permission: ''
  },
  {
    path: 'notification',
    element: lazy(() => import('pages/residents/Notification')),
    useAuth: true,
    permission: ''
  }
];

export const residentRoutes = {
  privateRoutes,
  publicRoutes
};
