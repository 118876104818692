import React from 'react';
import PropTypes from 'prop-types';
import { BILLTYPES } from 'utility/constants';
import { ReactComponent as Donations } from 'assets/images/svg/donations.svg';
import { ReactComponent as TopUp } from 'assets/images/svg/icon_electricity.svg';
import { ReactComponent as Service } from 'assets/images/svg/service_charge.svg';
import { ReactComponent as Power } from 'assets/images/svg/power.svg';
import { ReactComponent as Coupon } from 'assets/images/svg/coupon.svg';

const BillIcon = ({ billType, width = 34, height = 34 }) => {
  switch (billType) {
    case BILLTYPES.power:
      return <Power width={width} height={height} />;

    case BILLTYPES.service:
      return <Service width={width} height={height} />;

    case BILLTYPES.topup:
      return <TopUp width={width} height={height} />;

    case BILLTYPES.cart:
      return <Coupon width={width} height={height} />;

    default:
      return <Donations width={width} height={height} />;
  }
};

BillIcon.propTypes = {
  billType: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default BillIcon;
