import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ArrowContainer, Popover } from 'react-tiny-popover';

export const Context = forwardRef(
  ({ children, className, icon, label }, ref) => {
    const [open, setOpen] = useState(false);

    const toggleContext = (event) => {
      setOpen((prevState) => !prevState);
      event.stopPropagation();
    };

    return (
      <Popover
        containerClassName={classnames('context', className)}
        isOpen={open}
        positions={['bottom', 'left']}
        reposition={false}
        ref={ref}
        onClickOutside={() => setOpen(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
          >
            <div className="context__menu" onClick={() => setOpen(false)}>
              {children}
            </div>
          </ArrowContainer>
        )}
      >
        {label ? (
          <button className="context__toggle" onClick={toggleContext}>
            {label}
          </button>
        ) : (
          <button className="btn context__toggle" onClick={toggleContext}>
            <span className={classnames(icon)}></span>
          </button>
        )}
      </Popover>
    );
  }
);

Context.displayName = 'Context';

Context.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string
};
