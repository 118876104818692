import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table as ReactTable } from 'react-bootstrap';
import Loader from 'components/Loader';
import { MobileContext } from 'contexts/mobile';
import { NoData } from './NoData';

export const Table = ({
  children,
  className,
  headers,
  icon,
  image,
  loading,
  noData,
  noDataClass,
  allowSmallScreen,
  mobileContent,
  leftContent,
  rightContent
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { isMobile } = useContext(MobileContext);

  useEffect(() => {
    if (isMobile && allowSmallScreen) setIsSmallScreen(isMobile);
    // eslint-disable-next-line
  }, [isMobile]);
  const renderMobileContent = () => {
    return mobileContent;
  };
  const renderSmallScreen = () => {
    return (
      <div className="d-flex justify-content-between align-middle m-3">
        <div>{leftContent}</div>
        <div>{rightContent}</div>
      </div>
    );
  };

  return (
    <div className="table-container scrollbar scrollbar--table">
      {!isSmallScreen ? (
        <ReactTable
          borderless
          hover
          className={classnames(className, {
            'col-sticky':
              !headers[headers.length - 1] ||
              headers[headers.length - 1].toLowerCase() === 'action'
          })}
        >
          {headers && (
            <thead>
              <tr>
                {headers.map((item, i) => (
                  <th
                    key={i}
                    style={{
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          {!loading && <tbody>{children}</tbody>}
        </ReactTable>
      ) : (
        <>
          {renderSmallScreen()} {renderMobileContent()}
        </>
      )}

      {loading && <Loader isLoading />}

      {!loading && (!children || children.length === 0) && (
        <NoData
          icon={icon}
          image={image}
          description={noData}
          className={noDataClass}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  data: PropTypes.array,
  headers: PropTypes.array,
  icon: PropTypes.string,
  image: PropTypes.string,
  items: PropTypes.array,
  itemsPerPage: PropTypes.number,
  loading: PropTypes.bool,
  noData: PropTypes.string,
  noDataClass: PropTypes.string,
  allowSmallScreen: PropTypes.bool,
  mobileContent: PropTypes.node,
  rightContent: PropTypes.node,
  leftContent: PropTypes.node
};
