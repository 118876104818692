import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/admin/modals/ConfirmationModal';
import BillIcon from 'components/residents/bill-card/BillIcon';
import { deleteCartItem } from 'services/mutations';
import { CartContext } from 'contexts/cart';
import { convertToTitleCase } from 'utility';
import { ITEMTYPES } from 'utility/constants';
import { Button } from 'react-bootstrap';
import CartPrice from './CartPrice';

const CartCard = ({ bill }) => {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const toggleEdit = () => setShowEdit((prevState) => !prevState);

  const { refetch } = useContext(CartContext);

  return (
    <>
      <ConfirmationModal
        show={confirmDeleteModal}
        mutationFunc={() => deleteCartItem(bill.id)}
        handleClose={() => setConfirmDeleteModal(null)}
        refetch={refetch}
      />

      <div className="bill-card-wrapper">
        <div className="bill-card">
          <div className="bill-card__icon">
            <BillIcon billType={bill.bill_type} />
          </div>
          <div className="bill-card__content">
            <div className="bill-card__textbox">
              <p className="bill-card__title">
                {convertToTitleCase(bill.name)}
              </p>

              <CartPrice
                item={bill}
                showEdit={showEdit}
                closeEdit={() => setShowEdit(false)}
              />
            </div>

            <div className="bill-card__buttons">
              {bill.item_type !== ITEMTYPES.outstanding && (
                <>
                  {bill.pricing_type !== 'FIXED' && !showEdit && (
                    <Button
                      className="bill-card__button bill-card__button--alt"
                      onClick={toggleEdit}
                    >
                      <span className="icon-edit" title="icon-edit" />
                    </Button>
                  )}

                  <Button
                    className="bill-card__button bill-card__button--alt"
                    onClick={() =>
                      setConfirmDeleteModal({ id: bill.id, name: bill.name })
                    }
                  >
                    <span className="icon-delete"></span>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CartCard.propTypes = {
  bill: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    billId: PropTypes.string,
    bill_type: PropTypes.string,
    item_type: PropTypes.string,
    price: PropTypes.number,
    pricing_type: PropTypes.string,
    quantity: PropTypes.number,
    unit_price: PropTypes.number
  })
};

export default CartCard;
