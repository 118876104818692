import { lazy } from 'react';

export const generalRoutes = [
  {
    path: 'page',
    element: lazy(() => import('pages/Default')),
    layout: true,
    permission: ''
  },
  {
    path: '404',
    element: lazy(() => import('pages/NoAccessPage')),
    layout: false,
    permission: ''
  }
];

export const authRoutes = [
  {
    path: '/',
    element: lazy(() => import('pages/auth/Login')),
    useAuth: false,
    permission: ''
  },
  {
    path: 'login',
    element: lazy(() => import('pages/auth/Login')),
    useAuth: false,
    permission: ''
  },
  {
    path: 'forgot-password',
    element: lazy(() => import('pages/auth/ForgotPassword')),
    useAuth: false,
    permission: ''
  },
  {
    path: 'reset-password',
    element: lazy(() => import('pages/auth/ResetPassword')),
    useAuth: false,
    permission: ''
  }
];
