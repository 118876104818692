/* eslint-disable eqeqeq */
import moment from 'moment';
import { BILLTYPES, ITEMTYPES, PRICINGTYPES } from './constants';

export const handleApiError = (err) => {
  const error = err?.response?.data;

  let formatedError = 'Something happened when trying to connect to the server';

  if (err?.response?.status === 500) {
    formatedError = 'Request failed with status code 500';
  }

  if (error.detail) formatedError = error.detail || error;
  if (!error.detail && typeof error !== 'object') formatedError = error;
  if (!error.detail && typeof error === 'object') {
    for (const [key, value] of Object.entries(error || {})) {
      formatedError = `${key} ${value}`;
    }
  }
  if (error?.errors) {
    for (const [key, value] of Object.entries(error.errors || {})) {
      formatedError = `${key}: ${value?.join(', ')}`;
    }
  }
  if (error.message) {
    if (typeof error.message === 'object') {
      for (const [key, value] of Object.entries(error.message || {})) {
        formatedError = `${key}: ${value}`;
      }
    } else {
      formatedError = error.message || error;
    }
  }

  return formatedError;
};

export const convertToTitleCase = (str) => {
  const sentence = str?.toLowerCase()?.split(' ');
  for (let i = 0; i < sentence?.length; i++) {
    sentence[i] = sentence[i][0]?.toUpperCase() + sentence[i]?.slice(1);
  }

  return sentence?.join(' ')?.replace(/_/g, ' ');
};

export const useAmount = () => {
  function convertToAmount(amount, prefix = '') {
    return `${prefix}${Number(amount || 0)
      .toFixed(prefix && 2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }

  return { convertToAmount };
};

export const slugify = (str, separator = '-') =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, separator)
    .replace(/^-+|-+$/g, '');

export const isArrayEmpty = (arr) => !arr || arr.length === 0;

export const closeRefDropdown = (ref) => {
  if (ref.current) {
    ref.current?.closeDropdown();
  }
};

export const truncateText = (str, length = 70, ellipsis = '...') => {
  if (!str) {
    return;
  }

  if (str.length <= length) {
    return str;
  }

  return `${str.substring(0, length)}${ellipsis}`;
};

export const cleanObj = (obj) => {
  for (const propName in obj) {
    if (!obj[propName]?.length) {
      // eslint-disable-next-line no-param-reassign
      delete obj[propName];
    }
  }
  return obj;
};

export const getCartData = (obj) => {
  if (!obj) {
    return;
  }

  if (obj.item_type === ITEMTYPES.outstanding) {
    return {
      ...obj.outstanding,
      name: obj.outstanding.description,
      billId: obj.outstanding.id,
      id: obj.id,
      price: obj.total_price,
      pricing_type: PRICINGTYPES.fixed,
      bill_type: BILLTYPES.cart,
      item_type: ITEMTYPES.outstanding,
      quantity: obj.quantity,
      unit_price: obj.unit_price
    };
  }

  return {
    ...obj.bill,
    billId: obj.bill.id,
    id: obj.id,
    quantity: obj.quantity,
    price: obj.total_price,
    unit_price: obj.unit_price
  };
};

export function downloadCsvHelper(filename, textInput, format) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `${
      format ||
      'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }, ${encodeURIComponent(textInput)}`
  );
  element.setAttribute('download', filename);
  element.setAttribute('target', '_blank');
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'paid':
      return '#3D8845';
    case 'pending':
      return '#F1ED7D';
    case 'failed':
      return '#EF4747';
    default:
      return '#000957';
  }
};

export const updateObj = (oldObj, newProps) => {
  return {
    ...oldObj,
    ...newProps
  };
};

export const handleFormError = (formValue) => {
  const formState = {};

  if (typeof formValue === 'object' && !Array.isArray(formValue)) {
    Object.keys(formValue).forEach((key) => {
      if (
        typeof formValue[key] === 'object' &&
        !Array.isArray(formValue[key])
      ) {
        Object.keys(formValue[key] || {}).forEach((key2) => {
          if (key2 === 'value' || key2 === 'label') {
            formState[key] = !formValue[key]?.value;
          } else if (!formValue[key2] && !formValue[key2] != 0) {
            formState[key2] = true;
          } else {
            formState[key2] = false;
          }
        });
      } else if (!formValue[key] && !formValue[key] != 0) {
        formState[key] = true;
      } else {
        formState[key] = false;
      }
    });
    return formState;
  }

  return {
    errorState: typeof formValue === 'string' ? !formValue?.length : formState
  };
};

export const dirtyFormInvalid = (formState, requiredArray = []) => {
  if (!Object.values(formState || {}).length) return true;
  return !!Object.keys(formState).filter((key) => {
    return requiredArray?.find((req) => req === key && Boolean(formState[key]));
  })?.length;
};

export const separateDateAndTime = (dateTime) => {
  const passDate = new Date(dateTime);
  return {
    date: passDate?.toLocaleDateString(),
    time: passDate?.toLocaleTimeString()
  };
};
export const combineDateTime = (date, time) => {
  const combine = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm A');
  return combine.format();
};
