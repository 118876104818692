import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button as ButtonComponent } from 'react-bootstrap';

export const Button = (props) => {
  const buttonProps = { ...props };
  delete buttonProps.children;
  delete buttonProps.loading;
  delete buttonProps.className;
  delete buttonProps.disabled;

  return (
    <ButtonComponent
      className={classnames(props.className, {
        'btn--loader': props.loading
      })}
      disabled={props.loading || props.disabled}
      {...buttonProps}
    >
      {!props.loading && props.children}
    </ButtonComponent>
  );
};

Button.defaultProps = {
  disabled: false,
  loading: false
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};
