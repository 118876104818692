import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import useEventListener from 'services/useEventListener';

export const MobileContext = createContext({
  isMobile: false,
  isTab: false
});

const MobileContextProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);

  const updateWindowDimensions = () => {
    setIsTab(window.innerWidth <= 990);
    setIsMobile(window.innerWidth <= 600);
  };

  useEventListener('resize', updateWindowDimensions);

  useEffect(() => {
    updateWindowDimensions();
  }, []);

  return (
    <MobileContext.Provider
      value={{
        isMobile,
        isTab
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

MobileContextProvider.propTypes = {
  children: PropTypes.node
};

export default MobileContextProvider;
