import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { InputField } from 'components/form';
import { ITEMTYPES, PRICINGTYPES } from 'utility/constants';
import { handleApiError, useAmount } from 'utility';
import { updateCartItem } from 'services/mutations';
import { toast } from 'react-toastify';
import { CartContext } from 'contexts/cart';
import { useMutation } from 'react-query';
import { UserContext } from 'contexts/user';
import { Button } from 'components/ui';

const CartPrice = ({ closeEdit, item, showEdit }) => {
  const { convertToAmount } = useAmount();
  const isFixed = item.pricing_type === PRICINGTYPES.fixed;
  const { refetch } = useContext(CartContext);
  const { userId } = useContext(UserContext);
  const inputRef = useRef(null);

  let billQty = item.quantity || 1;
  let billAmount = item.price || 0;

  const { mutate: handleEdit, isLoading: isUpdating } = useMutation(
    updateCartItem,
    {
      enabled: !!userId,
      onError: (error) => {
        toast.error(handleApiError(error));
      },
      onSuccess: () => {
        toast.success('Item updated in cart successfully');
        closeEdit();
        refetch();
      }
    }
  );

  const onEdit = debounce((newQty = undefined, newPrice = undefined) => {
    handleEdit({
      id: item.id,
      payload: {
        total_price: newPrice,
        quantity: newQty,
        bill: item.item_type !== ITEMTYPES.outstanding ? item.billId : null,
        outstanding:
          item.item_type === ITEMTYPES.outstanding ? item.billId : null
      }
    });
  }, 600);

  const increaseQty = () => {
    billQty += 1;
    billAmount = item.unit_price * billQty;
    onEdit(billQty, billAmount);
  };

  const decreaseQty = () => {
    if (billQty !== 1) {
      const newQty = billQty - 1;
      const newPrice = item.unit_price * newQty;
      onEdit(newQty, newPrice);
    }
  };

  if (
    item.pricing_type === PRICINGTYPES.fixed &&
    item.item_type === ITEMTYPES.outstanding
  ) {
    return (
      <p className="bill-card__amount">{convertToAmount(item.price, '₦')}</p>
    );
  }
  const showQuantity = ['POWER', 'TOPUP'].indexOf(item.bill_type) === -1;
  if (
    item.pricing_type === PRICINGTYPES.fixed &&
    item.item_type !== ITEMTYPES.outstanding
  ) {
    return (
      <div className="bill-card__qty">
        {showQuantity && (
          <button
            disabled={billAmount === item.unit_price}
            onClick={decreaseQty}
          >
            -
          </button>
        )}
        <p className="bill-card__amount">{convertToAmount(billAmount, '₦')}</p>
        {showQuantity && <button onClick={increaseQty}>+</button>}
      </div>
    );
  }

  if (!isFixed && !showEdit) {
    return (
      <p className="bill-card__amount">{convertToAmount(billAmount, '₦')}</p>
    );
  }

  return (
    <div className="bill-card__form pt-3">
      <InputField
        placeholder="₦0.00"
        className="mb-0"
        name={item.id}
        defaultValue={item.price}
        type="number"
        ref={inputRef}
      />

      <Button
        className="bill-card__button bill-card__button--edit"
        onClick={() => onEdit(1, inputRef.current?.value)}
        loading={isUpdating}
      >
        Update
      </Button>
    </div>
  );
};

CartPrice.propTypes = {
  closeEdit: PropTypes.func,
  item: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    billId: PropTypes.string,
    bill_type: PropTypes.string,
    item_type: PropTypes.string,
    price: PropTypes.number,
    pricing_type: PropTypes.string,
    quantity: PropTypes.number,
    unit_price: PropTypes.number
  }),
  handleEdit: PropTypes.func,
  showEdit: PropTypes.bool
};

export default CartPrice;
