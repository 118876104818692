import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { fetchCart } from 'services/query';
import { toast } from 'react-toastify';
import { handleApiError } from 'utility';

export const CartContext = createContext({
  cartItems: null,
  enabled: false,
  isLoading: false,
  refetch: () => null,
  setEnabled: () => null,
  showCart: false,
  toggleCart: () => null
});

const CartContextProvider = ({ children }) => {
  const [showCart, setShowCart] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const {
    isLoading,
    error,
    data: cartItems,
    isError,
    refetch
  } = useQuery('cart', fetchCart, {
    enabled
  });

  const toggleCart = () => setShowCart((prevState) => !prevState);

  if (isError) {
    toast.error(handleApiError(error));
  }

  return (
    <CartContext.Provider
      value={{
        cartItems,
        enabled,
        isLoading,
        setEnabled,
        showCart,
        toggleCart,
        refetch
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartContextProvider.propTypes = {
  children: PropTypes.node
};

export default CartContextProvider;
