/* eslint-disable */
import axios from 'axios';
import { getRefreshToken, getToken, isAuthenticated } from './auth';
import { queryParamsHelper } from './query';

export const baseUrl =
  process.env.REACT_APP_API_BASE_URL ||
  'https://api.dev.facilitybills.com/api/v1/';

export const generateSignedDownloadLink = (path, queryParams) => {
  delete queryParams.filterValue;
  queryParams._token = getToken();
  return `${baseUrl}${path}${queryParamsHelper(queryParams)}`;
};
const Api = axios.create({
  baseURL: baseUrl
});

// Request interceptor for API calls
Api.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: isAuthenticated() ? `Bearer ${getToken()}` : ''
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error?.config;

    if (error?.response?.status === 401 && originalRequest?._retry) {
      const rToken = getRefreshToken();
      if (rToken) {
        const access_token = await refreshToken(rToken);
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + access_token;
      }
      return Api(originalRequest);
    }
    return Promise.reject(error);
  }
);

function refreshToken(token) {
  return Api.post('/auth/token/refresh/', {
    refresh: token
  });
}

export default Api;
